/* You can add global styles to this file, and also import other style files */
html {
    font-size: 12px;
}

body {
    margin: 0;
    padding: 0;
}

.p-datatable .p-datatable-scrollable-header, .p-datatable .p-datatable-scrollable-footer {
    background: #f4f4f4 !important;
}

.bold-font {
  font-weight: bold !important;
}